.popup-overlay {
  position: fixed; /* Ensure it stays on top */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything */
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centering trick */
}

.popup-content h3 {
  margin-bottom: 10px;
}

.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adds spacing between buttons */
  margin-top: 15px;
}

.btn-contact {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  flex: 1;
  max-width: 120px;
}

.btn-contact:hover {
  background-color: #0056b3;
}

.btn-close {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  flex: 1;
  max-width: 120px;
}

.btn-close:hover {
  background-color: #cc0000;
}
